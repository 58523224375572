import FormSerializer from '@/components/form-block/adapter';
import { asText, richText, stripRichTextWrapperTag } from '@/utils/html';
import { adapter as imageAdapter } from '@/components/cloud-image/utils';
import { getEkomiData, getRating } from '~/utils/ekomi';
import {
  productLinks,
  contactLinks,
  marketLinks,
  crossSellTitleLinks,
} from '@/utils/fetch-links';

export async function getConfirmation(
  data,
  items,
  formUid,
  $enhancedLinkSerializer,
  $prismic
) {
  const link =
    data.form_confirmation_link &&
    $enhancedLinkSerializer(data.form_confirmation_link);
  const secondaryLink =
    data.form_confirmation_secondary_link &&
    $enhancedLinkSerializer(data.form_confirmation_secondary_link);

  const postRequest = data.form_confirmation_link_post_data;
  const formId = `confirmation-${formUid}`;
  const formAction = link?.href || secondaryLink?.href;
  const submitAttributes = { type: 'submit', form: formId };
  let primaryCta,
    secondaryCta = null;

  if (link && data.form_confirmation_link_label) {
    primaryCta = {
      label: data.form_confirmation_link_label,
      link: postRequest ? submitAttributes : link,
    };
  }

  if (secondaryLink && data.form_confirmation_secondary_link_label) {
    secondaryCta = {
      label: data.form_confirmation_secondary_link_label,
      link: postRequest ? submitAttributes : secondaryLink,
    };
  }

  const crossSellTitle = data.cross_sell_title
    ? richText(data.cross_sell_title)
    : null;
  const filteredItems = items.filter(
    ({ cross_sell_cards }) => cross_sell_cards.id
  );
  const crossSellCards = await parseCrossSellCards(
    filteredItems,
    $prismic,
    $enhancedLinkSerializer
  );

  return {
    icon: imageAdapter(data.form_confirmation_icon),
    title: stripRichTextWrapperTag(data.form_confirmation_title),
    text: richText(data.form_confirmation_text),
    primaryCta,
    secondaryCta,
    formId,
    formAction,
    crossSellTitle,
    crossSellCards,
  };
}

export function advantagesPushAdapter({ data }, $enhancedLinkSerializer) {
  if (!data) {
    return;
  }

  const listItems = data.advantage_entries.map((item) => {
    return {
      icon: imageAdapter(item.avantage_entry_icon),
      text: item.advantage_entry_title,
      link: $enhancedLinkSerializer(item.advantage_entry_link),
    };
  });

  return {
    title: stripRichTextWrapperTag(data.advantage_title),
    listItems,
    formPushDisplay: true,
  };
}

const getValidTrackLabel = (link) => {
  const isArray = Array.isArray(link.field?.data?.title);

  if (isArray) {
    return asText(link.field.data.title).replace(/\n/g, ' ');
  }
  return link.field?.data?.title || link.field?.data?.breadcrumb_title || '';
};

export const parseCrossSellCards = async (
  items,
  $prismic,
  $enhancedLinkSerializer
) => {
  const cmsItems = await $prismic.api.getByIDs(
    items.map((item) => item.cross_sell_cards.id),
    {
      fetchLinks: [
        ...productLinks,
        ...contactLinks,
        ...marketLinks,
        ...crossSellTitleLinks,
      ],
    }
  );
  const crossSellCards = cmsItems.results.map((cardItem) => {
    const link = $enhancedLinkSerializer(cardItem.data?.link);
    const trackLabel = link && getValidTrackLabel(link);

    const data = {
      title: cardItem.data?.title,
      price: stripRichTextWrapperTag(cardItem.data?.description),
      image: imageAdapter(cardItem.data?.image),
      button: {
        link: link,
        label: cardItem.data?.link_label,
        trackLabel,
      },
    };
    return data;
  });
  return crossSellCards;
};

async function adapter(data, pageData, context) {
  const { primary, items } = data;

  const { $enhancedLinkSerializer, $prismic } = context;
  const comparadiseConfig = primary?.comparadise_config?.data;

  let iframe = null;
  if (primary.iframe_id && primary.iframe_url) {
    iframe = {
      iframeId: primary.iframe_id,
      iframeUrl: primary.iframe_url,
    };
  }

  const formUid = !iframe ? primary.form.uid : null;
  const form = !iframe
    ? await FormSerializer(primary.form, pageData, context)
    : null;
  const confirmation = !iframe
    ? await getConfirmation(
        primary,
        items,
        formUid,
        $enhancedLinkSerializer,
        $prismic
      )
    : null;
  const hasAdvantagesPush = primary.hasadvantagespush;

  if (!form && !iframe && !comparadiseConfig) {
    return null;
  }

  const introSupertitle = richText(primary.form_intro_supertitle);
  const introTitle = stripRichTextWrapperTag(primary.form_intro_title);
  const introDescription = richText(primary.form_intro_description);
  const hasIntro = introSupertitle || introTitle || introDescription;

  const ekomiAccount = pageData.market?.data?.ekomi_account || null;
  const withEkomiRating = primary.with_ekomi_rating;
  let ekomiData, ekomiInfobulle;

  if (withEkomiRating) {
    ekomiInfobulle = primary.ekomi_rating_infobulle;
    ekomiData = await getEkomiData(ekomiAccount, 'reviews', context);
  }

  return {
    form,
    iframe,
    iframeBackgroundColor: iframe ? primary.iframe_background_color : null,
    content: {
      overlap: primary.form_push_overlap,
      ratingsInfoData:
        withEkomiRating && ekomiData
          ? {
              rating: ekomiData.average_rating,
              ratingLabel: `<b>${getRating(
                ekomiData.average_rating,
                context.i18n
              )}/5</b> (${ekomiData.review_count} avis)`,
              infobulle: ekomiInfobulle
                ? `<button data-tooltip="${ekomiInfobulle}">i</button>`
                : null,
            }
          : null,
      intro: hasIntro
        ? {
            supertitle: introSupertitle,
            title: introTitle,
            description: introDescription,
          }
        : null,
      title: stripRichTextWrapperTag(primary.form_push_title),
      description: richText(primary.form_push_content),
      hasAdvantagesPush,
      advantagesPushData: hasAdvantagesPush
        ? advantagesPushAdapter(primary.advantagespush, $enhancedLinkSerializer)
        : null,
    },
    confirmation,
    comparadiseConfig,
    primary: {
      isPrimary: primary.is_premium,
      primaryUrl: $enhancedLinkSerializer(primary.page_de_redirection_premium),
    },
  };
}

export default adapter;
